
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import headerBanner from '../../home/banner.vue'

export default {
    name: 'monitor',
    components: {
        homeTitlepc,
        headerBanner,
        
    },

    mounted () {
     
    },

    created(){
       

    },

    methods: {
        changeCategoryr(v){
            console.log("ppp");
            this.rNum=v
        },
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }


        }
      

    },

    data () {
        return {
            config,
            rNum:0,
            CategoryList:[
                {
                    name:'科技咨询'
                },
                {
                    name:'创业孵化'
                },
                {
                    name:'资质认证'
                },
            ],
            works1:[
                {
                    img:require('./images/s1.png'),
                    name:'高企认定',
                    show:'奖励到达200万减免40%所得税'
                },
                {
                    img:require('./images/s2.png'),
                    name:'资金扶持',
                    show:'优享国家政策助力企业发展'
                },
                {
                    img:require('./images/s3.png'),
                    name:'可行性研究报告',
                    show:'精准分析评估降低项目启动风险'
                },
                {
                    img:require('./images/s4.png'),
                    name:'科技评估',
                    show:'专业团队评估助力技术资产变现'
                },
                {
                    img:require('./images/s5.png'),
                    name:'科技信息',
                    show:'科技信息全捕捉竞争情报早知道'
                },
                {
                    img:require('./images/s6.png'),
                    name:'规划研究',
                    show:'深度剖析发展现状科学规划战略方向'
                },
            ],
            works2:[
                {
                    img:require('./images/s7.png'),
                    name:'商标申请',
                    show:'商标早注册品牌保护防火墙'
                },
                {
                    img:require('./images/s8.png'),
                    name:'商业计划书',
                    show:'重塑商业模式三分钟打动投资人'
                },
                {
                    img:require('./images/s9.png'),
                    name:'代理记账',
                    show:'告别繁琐记账报税节约创业每一分钟'
                },
                {
                    img:require('./images/s10.png'),
                    name:'法律服务',
                    show:'资深律师创业法务一站式搞定'
                },
            ],
            works3:[
                {
                    img:require('./images/s11.png'),
                    name:'资质申请',
                    show:'多年资质认证经验150+产品任选'
                },
                {
                    img:require('./images/s12.png'),
                    name:'双软评估',
                    show:'招投标双软助推增值税即征即退'
                },
                {
                    img:require('./images/s13.png'),
                    name:'体系认证',
                    show:'专业团队辅导权威机构发证'
                },
                {
                    img:require('./images/s14.png'),
                    name:'软著登记',
                    show:'快速高效办理担保登记无后顾之忧'
                },
                {
                    img:require('./images/s15.png'),
                    name:'3C认证',
                    show:'市场敲门砖提高市场占有率'
                },
                {
                    img:require('./images/s16.png'),
                    name:'知产贯标',
                    show:'优先获得政府补助支持'
                },
            ],
            works4:[
                {
                    bgimg:require('./images/p1.png'),
                    topic:'找技术',
                    list:[
                        {
                            img:require('./images/t1.png'),
                            name:'技术难题对接',
                        },
                        {
                            img:require('./images/t2.png'),
                            name:'技术中试',
                        },
                        {
                            img:require('./images/t3.png'),
                            name:'资产评估',
                        },
                    ]
                    
                },
                {
                    bgimg:require('./images/p2.png'),
                    topic:'找专家',
                    list:[
                        {
                            img:require('./images/t4.png'),
                            name:'产学研合作',
                        },
                        {
                            img:require('./images/t5.png'),
                            name:'共建创新平台',
                        },
                        {
                            img:require('./images/t6.png'),
                            name:'技术难题攻关',
                        },
                    ]
                    
                },
                {
                    bgimg:require('./images/p3.png'),
                    topic:'找政策',
                    list:[
                        {
                            img:require('./images/t7.png'),
                            name:'政策匹配',
                        },
                        {
                            img:require('./images/t8.png'),
                            name:'高企测评',
                        },
                        {
                            img:require('./images/t9.png'),
                            name:'政策咨询',
                        },
                    ]
                    
                },
                {
                    bgimg:require('./images/p4.png'),
                    topic:'投融资',
                    list:[
                        {
                            img:require('./images/t10.png'),
                            name:'商业计划书',
                        },
                        {
                            img:require('./images/t11.png'),
                            name:'精选资金',
                        },
                        {
                            img:require('./images/t12.png'),
                            name:'科技保险',
                        },
                    ]
                    
                },
            ]
           
            
        }
    }
}
